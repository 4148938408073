import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const solutions = [
  {
    name: "Hospedaje de VPS",
    description: "Aspectos esenciales del hospedaje VPS",
    href: "https://client.by-hoster.net/store/vps",
    icon: "text-white fa-solid fa-server fa-2xl",
  },
  {
    name: "Hospedaje de WEB",
    description: "Tu presencia en línea",
    href: "https://client.by-hoster.net/store/plesk",
    icon: "text-white fa-solid fa-globe fa-2xl",
  },
  {
    name: "Hospedaje de NodeJS",
    description: "Desarrollo ilimitado con Node.js",
    href: "https://client.by-hoster.net/store/nodejs",
    icon: "fa-brands fa-node-js fa-2xl text-white",
  },
  {
    name: "Hospedaje de Python",
    description: "Tu entorno personalizado de Python",
    href: "https://client.by-hoster.net/store/python",
    icon: "fa-brands fa-python fa-2xl text-white",
  }

];

export default function Cloud() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className="relative group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="inline-flex items-center gap-x-1 text-sm max-xl:text-base font-semibold leading-6 text-white cursor-pointer">
        <span>Cloud</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </span>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg">
            <div className="p-4">
              {solutions.map((item) => (
                <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-bg-g">
                    <i className={item.icon}></i>
                  </div>
                  <div>
                    <a href={item.href} className="font-semibold text-gray-900">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
