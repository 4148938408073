import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const solutions = [
    {
        name: "Anglais",
        description: "Changez la langue du site pour l'Anglais",
        href: "/en",
        icon: "/img/en.svg",
        style: "rounded-sm w-8"
    },
    {
        name: "Espagnol",
        description: "Changez la langue du site pour l'Espagnol",
        href: "/es",
        icon: "/img/es.svg",
        style: "rounded-sm w-8"
    },
    {
        name: "Allemand",
        description: "Changez la langue du site pour l'Allemand",
        href: "/de",
        icon: "/img/de.svg",
        style: "rounded-sm w-8"
    }
];

export default function Langues() {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    return (
        <div
            className="group pt-1"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span className="inline-flex items-center gap-x-1 text-sm max-xl:text-base font-semibold leading-6 text-white cursor-pointer">
                <span><img src="/img/fr.svg" className="w-6 rounded-sm" alt="" /></span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </span>

            <Transition
                show={isOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <div className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-4">
                            {solutions.map((item) => (
                                <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-white">
                                        <img src={item.icon} className={item.style} alt={item.name} />
                                    </div>
                                    <div>
                                        <a href={item.href} className="font-semibold text-gray-900">
                                            {item.name}
                                            <span className="absolute inset-0" />
                                        </a>
                                        <p className="mt-1 text-gray-600">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
}
