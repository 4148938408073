import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import Cloud from "./Cloud";
import Gaming from "./Gaming";
import Langues from "./Langues";

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="text-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <NavLink to="/" className="text-xl -m-1.5 p-1.5" aria-label="Logo de By-Hoster">
              <img src="/img/logo-big.webp" className="w-32" />
            </NavLink>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <Cloud />
            <Gaming />
            <NavLink
              to={"https://by-h.cloud"}
              className="text-sm font-semibold leading-6 text-white"
            >
              ByCloud
            </NavLink>
            <NavLink
              to={"mailto:contact@by-hoster.com"}
              className="text-sm font-semibold leading-6 text-white"
            >
              Nous Contacter
            </NavLink>
            <Langues/>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <NavLink
              to="https://client.by-hoster.net/login"
              className="rounded-md bg-[#002BB7] px-3.5 p-2 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2"
            >
              <i className="fa-regular fa-user mr-2"></i>Connexion
            </NavLink>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#000C36] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <NavLink to="/" className="text-xl -m-1.5 p-1.5">
                <img src="/img/logo.webp" className="w-7" />
              </NavLink>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <div className="-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"><Cloud /></div>
                  <div className="-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"><Gaming /></div>
                  <NavLink
                    to={"https://by-h.cloud"}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"
                  >
                    ByCloud
                  </NavLink>
                  <NavLink
                    to={"mailto:contact@by-hoster.com"}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"
                  >
                    Nous Contacter
                  </NavLink>
                  <div className="-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"><Langues /></div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
