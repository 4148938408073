import React from 'react';
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const E404 = () => {
  return (
    <div className="w-full h-screen bg-cover bg-center bg-bgb">
      <div className="backdrop-blur-lg flex justify-center items-center h-screen">
        <div className="max-xl:w-[80%] w-[40%]">
          <h1 className="text-center text-4xl font-bold tracking-tight text-white sm:text-6xl" data-aos="zoom-in">
            Error 404
          </h1>
          <p className="text-center mt-6 text-lg leading-8 text-white" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
            Page not found
          </p>
          <p className="text-center mt-8" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
            <NavLink
              to="/"
              className="rounded-md bg-[#002BB7] p-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
            >
              <i class="fa-solid fa-arrow-left text-white"></i> Go back to home page
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default E404;
