import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/fr/Home';
import E404 from './pages/fr/E404';
import HomeEn from './pages/en/HomeEn';
import HomeEs from './pages/es/HomeEs';
import HomeDe from './pages/de/HomeDe';

function App() {
  const getBrowserLanguage = () => {
    const navigatorLanguage = navigator.language || navigator.userLanguage;
    return navigatorLanguage.split('-')[0];
  };
  const browserLanguage = getBrowserLanguage();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            (browserLanguage === 'en' && <Navigate to='/en' />) ||
            (browserLanguage === 'fr' && <Navigate to='/fr' />) ||
            (browserLanguage === 'de' && <Navigate to='/de' />) ||
            (browserLanguage === 'es' && <Navigate to='/es' />) ||
            (browserLanguage != 'es' && <Navigate to='/en' />) ||
            <Home />
          }
        />
        <Route path='/fr' element={<Home />} />
        <Route path='/en' element={<HomeEn />} />
        <Route path='/de' element={<HomeDe />} />
        <Route path='/es' element={<HomeEs />} />
        <Route path='*' element={<E404 />} />
      </Routes>
 
    </BrowserRouter>
  );
}

export default App;
