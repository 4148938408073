import { NavLink } from "react-router-dom";
import Navbar from "../../components/en/Navbar";
import json from "../../components/en/env.json";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const editable = json;

const HomeEn = () => {
  return (
    <div>
      <div>
        <Navbar /> {/* composant navbar */}
        {/* debut de la section hero */}
        <div className="w-full h-screen bg-cover bg-center bg-bgb">
          <div className="backdrop-blur-lg flex justify-center items-center h-screen">
            <div className="max-xl:w-[80%] w-[40%]">
              <h1
                className="text-center text-4xl font-bold tracking-tight text-white sm:text-6xl"
                data-aos="zoom-in"
              >
                Reliable Quality Hosting at Reduced Prices
              </h1>
              <p
                className="text-center mt-6 text-lg leading-8 text-white"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="400"
              >
                By-Hoster was created to offer you affordable rates and
                high-quality hosting services to help you realize your greatest
                projects.
              </p>
              <p
                className="text-center mt-8"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="600"
              >
                <NavLink
                  to="https://client.by-hoster.net/store"
                  className="rounded-md bg-[#002BB7] p-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
                >
                  <i className="fa-solid fa-cart-shopping text-white"></i> Shop
                </NavLink>
              </p>
            </div>
          </div>
        </div>
        {/* debut de la section services */}
        <div className="text-white bg-bg-g w-full bg-cover bg-center">
          <div className="backdrop-blur-lg">
            <div>
              <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
                <div className="mx-auto max-w-lg text-center">
                  <h2
                    className="text-3xl font-bold sm:text-4xl"
                    data-aos="zoom-in"
                  >
                    Our Hosting Services
                  </h2>
                  <p className="mt-4 text-gray-300" data-aos="zoom-in">
                    A list of the services we offer
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/plesk"
                  >
                    <i className="fa-solid fa-globe fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Web Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer web hosting for your websites at very affordable
                      prices.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/vps"
                  >
                    <i className="fa-solid fa-server fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      VPS Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer VPS at very affordable rates with high-quality
                      Anti-DDoS protection!
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/pterobox"
                  >
                    <i className="fa-solid fa-gamepad fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      PteroBox Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      You can create and manage multiple game servers and more
                      without the complexity of VPS management, thanks to our
                      panel.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gtarp"
                  >
                    <img
                      src="/img/fivem.svg"
                      className="w-10"
                      alt="fivem vectorial icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      FiveM Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer FiveM servers with high performance for optimal
                      gameplay quality!
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/minecraft"
                  >
                    <img
                      src="/img/minecraft.svg"
                      className="w-8"
                      alt="minecraft vectorial icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Minecraft Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer Minecraft servers so you can create a server for
                      friends or even public play without worrying about
                      performance!
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gmod"
                  >
                    <img
                      src="/img/gmod.svg"
                      className="w-10"
                      alt="garry's mod vectorial icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Garry's Mod Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer Garry's Mod servers for easy and well-performing
                      gameplay.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/rust"
                  >
                    <img
                      src="/img/rust.svg"
                      className="w-10"
                      alt="rust vectorial icon"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Rust Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer high-performance Rust servers for an optimal
                      gaming experience with unparalleled gameplay quality.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/nodejs"
                  >
                    <i className="fa-brands fa-node-js fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      NodeJS Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer NodeJS packages if you are a Discord bot
                      developer or any other testing, with low-latency quality
                      at affordable prices to start.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/python"
                  >
                    <i className="fa-brands fa-python fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Python Hosting
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      We offer Python3 packages if you are a Discord bot
                      developer or any other testing, with low-latency quality
                      at affordable prices to start.
                    </p>
                  </a>
                </div>
                <div className="mt-12 text-center">
                  <NavLink
                    to="https://client.by-hoster.net/register"
                    className="p-2 rounded-md bg-[#002BB7] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    Sign Up{" "}
                    <i className="fa-solid fa-arrow-right text-white"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          {/* debut de la section stats */}

          <div className="backdrop-blur-lg py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <dl
                className="grid grid-cols-1 gap-x-2 gap-y-16 text-center lg:grid-cols-4"
              >
                {editable.stats.map((stat) => (
                  <div
                    key={stat.id}
                    className="mx-auto flex max-w-xs flex-col gap-y-4"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <dt className="text-base leading-7 text-gray-200">
                      {stat.nom}
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      <i className={stat.fontawsome}></i>
                    </dd>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      {stat.chiffre}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* debut de la section presentation */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg">
            <section
              id="features"
              className="relative block px-6 py-10 md:py-20 md:px-10"
            >
              <div className="relative mx-auto max-w-5xl text-center">
                <h2
                  className="text-3xl font-bold sm:text-4xl"
                  data-aos="zoom-in"
                >
                  Why Choose By-Hoster?
                </h2>
                <p className="mt-4 text-gray-300" data-aos="zoom-in">
                  By-Hoster is the ideal French hosting provider for
                  individuals, enthusiasts, and businesses. The performance of
                  our servers allows you to have maximum speed for your web
                  projects!
                </p>
              </div>
              <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-database fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Technology
                  </h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Our offers are powered by the latest technologies to ensure
                    stability for your services.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">Backup</h2>
                  <p className="mt-1 text-sm text-gray-300">
                    We have an automatic and scheduled backup system every day
                    to secure your data.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-phone-volume fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">Support</h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Customer support is our priority. We strive to provide the
                    best possible support to our clients.
                  </p>
                </div>
              </div>
              <div className="absolute bottom-0 left-0 z-0 h-1/3 w-full"></div>
              <div className="absolute bottom-0 right-0 z-0 h-1/3 w-full"></div>
            </section>
          </div>

          {/* debut de la section faq */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg py-8 sm:py-12">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Frequently Asked Questions.
            </h1>
            <p
              className="mt-4 text-gray-300 mb-4 text-center"
              data-aos="zoom-in"
            >
              Answers to the most common questions.
            </p>
            <div
              className="mt-12 xl:flex justify-center xl:items-center"
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              <img
                src="/img/faq.webp"
                className="xl:mr-28 max-sm:w-64 w-96 cloudeffect max-xl:mx-auto max-xl:py-8"
                alt="faq section image"
              />
              <div className="grid divide-y divide-neutral-200 max-w-xl max-sm:w-64 max-xl:mx-auto">
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="200"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span className="xl:pr-[227px]">
                        Do you have quality Anti-DDoS protection?
                      </span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Yes, of course! We have both Layer 4 and Layer 7 Anti-DDoS
                      protection.
                    </p>
                  </details>
                </div>
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="400"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>Where are your machines hosted?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      All our infrastructure is located in France, our machines
                      and infrastructure belong to By-Hoster and are precisely
                      situated in the "Nouvelle-Aquitaine" region.
                    </p>
                  </details>
                </div>
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="600"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>Do you offer 24/7 support?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Our support is active every day and strives to respond to
                      you as quickly as possible!
                    </p>
                  </details>
                </div>
              </div>
            </div>
          </div>

          {/* debut de la section avis */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg py-8">
            <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
              <h1
                className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
                data-aos="zoom-in"
              >
                Customer Reviews
              </h1>
              <p
                className="mt-4 text-gray-300 mb-8 text-center"
                style={{ fontStyle: "italic" }}
                data-aos="zoom-in"
              >
                Some reviews have been paraphrased for clarity, but the essence
                and general opinion of customers have been preserved. The
                modifications have not altered the meaning or integrity of the
                original comments. The reviews are translated from French
              </p>
              <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                    <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="user icon from top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Tom Marcos
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "I bought a VPS from them, I couldn't set up a Pterodactyl
                    panel. I opened a ticket, and in 10 minutes, I had it.
                    That's why I highly recommend By-Hoster!"
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                    <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="user icon from top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Le1er Xero
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "A hosting provider that is respectful towards its clients,
                    with a very responsive support and an excellent hosting
                    service to offer. I think I can say that it is a quality
                    hosting provider!"
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                  <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="user icon from top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Nain Ptit
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "I've been using this hosting provider for not very long for
                    a Minecraft server site, and I find it super convenient.
                    There's practically no lag for the cheapest version."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* debut de la section footer */}
        <footer className="bg-footerbg w-full bg-cover bg-center">
          <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div>
                <img
                  src="/img/logo-big.webp"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  className="w-44"
                  alt="by-hoster horizontal logo"
                />
                <p
                  className="mt-4 max-w-xs text-white"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  By-Hoster was created in 2020 by Planetium Network to offer
                  you affordable rates and quality hosting services to help you
                  achieve your biggest projects.
                </p>
                <ul className="mt-8" data-aos="fade-up" data-aos-easing="ease">
                  <li>
                    <a
                      href="mailto:contact@by-hoster.com"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p className="">
                        <i className="fa-solid fa-envelope"></i>{" "}
                        contact@by-hoster.com
                      </p>
                    </a>
                  </li>
                  <li>
                    <div className="text-gray-300 transition hover:opacity-75">
                      <p>
                        <i className="fa-solid fa-globe"></i> www.by-hoster.net
                      </p>
                    </div>
                  </li>
                  <li>
                    <a
                      href="tel:+33564720053"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p>
                        <i className="fa-solid fa-phone"></i> +33564720053
                      </p>
                    </a>
                  </li>
                  <li className="mt-8">
                    <a
                      href="https://www.top-heberg.com/hebergeur/by-hoster-300"
                      target="_blank"
                      title="By Hoster sur Top-heberg.com"
                    >
                      <img
                        src="https://www.top-heberg.com/badge/by-hoster-300?s=refer"
                        width="250"
                        alt="Top-heberg.com"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4"
                data-aos="fade-up"
                data-aos-easing="ease"
              >
                <div>
                  <p className="font-medium text-white">Gaming Services</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/pterobox"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        PteroBox Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gtarp"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        GTA RP Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/minecraft"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Minecraft Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gmod"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Garry's Mod Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/rust"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Rust Hosting
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Cloud Services</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/vps"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        VPS Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/plesk"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        WEB Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/nodejs"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        NodeJS Hosting
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/python"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Python Hosting
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Other Links</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/client"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Client Area
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://by-h.cloud"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        ByCloud
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://statut.by-hoster.net"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Status
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:contact@by-hoster.com"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Legal (in french)</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="/CGU.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="/ML.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Legal Notices
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomeEn;
